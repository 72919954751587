<template>
	<div class="tabulation">
		<el-card class="chart-card">
			<div style="margin-top: 20px;">
				<el-form ref="ruleFormRef" :model="form" :rules="rules" class="demo-ruleForm" :size="formSize"
					status-icon>
					<!-- 报名年份 -->
					<el-form-item label="报名年份" prop="value2">
						<div class="bilke">
							<el-date-picker v-model="form.value2" type="year" size="large" placeholder="请选择报名年份"
								@change="insuch" />
						</div>
					</el-form-item>
					<!-- 延期时间 -->
					<el-form-item label="延期时间" prop="value1">
						<div class="bilke">
							<el-date-picker size="large" v-model="form.value1" type="datetime" placeholder="请选择延期时间"
								:default-time="defaultTime2" />
						</div>
					</el-form-item>
					<!-- 备注 -->
					<el-form-item label="备注" prop="reason">
						<div class="aaaa">
							<el-input type="textarea" v-model="form.reason" placeholder="请填写备注"></el-input>
						</div>
					</el-form-item>
					<!-- 提示 -->
					<div class="hint">
						<div class="remind">提示：</div>
						<div>
							<div class="become"> 本操作不可撤销，请谨慎操作。</div>
						</div>
					</div>
					<!-- 提交按钮 -->
					<div class="submit">
						<el-form-item>
							<el-button type="primary" :loading="ConfirmLoading" @click="onSubmit">提交</el-button>
						</el-form-item>
					</div>
				</el-form>
			</div>
		</el-card>
	</div>
</template>

<script>
	import {
		ref,
		reactive,
		onMounted,
		onActivated,
	} from "vue";
	import {
		ZClist,
		HFparametersadd,
		manualExtension,
	} from '../../utils/api'
	import {
		ElMessage
	} from "element-plus";
	import qs from 'qs'
	import {
		useRouter,
		useRoute
	} from "vue-router";
	import {
		useStore
	} from "vuex";
	export default {
		name: "manualExtension",
		setup() {
			const route = useRoute();
			onMounted(() => {})
			// 关闭当前页
			const store = useStore();
			const current = ref('')
			const expression = ref([])
			const Indexs = ref(null)
			let value2 = ref('')
			let texture = ref([])
			const visible = ref(false);
			const defaultTime2 = [
				new Date(2000, 1, 1, 0, 0, 0),
			]
			let translate = ref(2)
			onActivated(() => {
				translate.value = route.params.UserId
			})
			// 按钮加载
			const ConfirmLoading = ref(false)
			// 表单内容
			const ruleFormRef = ref(null)
			const form = reactive({
				registrationTime: "",
				expirationTime: "",
				reason: "",
			})
			// 表单提交
			const router = useRouter();
			const loa = ref(false)
			const onSubmit = () => {

				loa.value = true
				//到期时间
				var e = ref(new Date(form.value1))
				var expire = ref('')
				expire.value = e.value.getFullYear() + '-' + (e.value.getMonth() + 1) + '-' + e.value.getDate() + ' ' +
					e.value.getHours() + ':' + e.value.getMinutes() + ':' + e.value.getSeconds();
				var expireTime = ref(new Date(expire.value).getTime() / 1000)
				// 报名年份
				var b = ref(new Date(form.value2))
				var expireTimes = ref(new Date(b.value).getTime() / 1000)
				// 表单校验
				ruleFormRef.value.validate((valid) => {
					console.log(valid)
					let data = {
						//活动id
						associationId: sessionStorage.getItem('activityIds'),
						//报名年份
						registrationTime: expireTimes.value,
						//延期时间
						expirationTime: expireTime.value,
						//备注
						reason: form.reason,
					}
					if (valid == true) {
						ConfirmLoading.value = true
						manualExtension(qs.parse(data)).then((res) => {
							console.log(res)
							if (res.data.code == 0) {
								ElMessage({
									message: '延期成功',
									type: "success",
									center: true,
								});
								setTimeout(() => {
									current.value = route.path
									expression.value = store.state.tagsList

									expression.value.forEach((item, index) => {
										if (current.value == item.path) {
											Indexs.value = index
										}
									})
									store.state.tagsList.splice(Indexs.value, 1)
									router.push({
										name: 'registrationStatistics',
										params: {
											userId: 1
										}
									});
									ConfirmLoading.value = false
								}, 500)
							} else {
								ElMessage({
									message: res.data.msg,
									type: "warning",
									center: true,
								});
								ConfirmLoading.value = false
							}
						})
					} else {
						ElMessage({
							message: "延期失败",
							type: "error",
							center: true,
						});
						ConfirmLoading.value = false
					}
					console.log(form)
				})
			}
			// 验证表单提示
			const rules = {
				value2: [{
					required: true,
					message: "请选择报名年份",
					trigger: "change"
				}, ],
				value1: [{
					required: true,
					message: "请选择延期时间",
					trigger: "change"
				}, ],
				reason: [{
					required: true,
					message: "请填写备注",
					trigger: "change"
				}, ],
			}
			return {
				// 提交按钮
				loa,
				// 表单内容
				ruleFormRef,
				form,
				// 表单提交按钮
				onSubmit,
				rules,
				defaultTime2,
				// 按钮加载
				ConfirmLoading,
				value2,
			}
		},
	}
</script>>

<style lang='scss' scoped>
	::v-deep .chart-card::-webkit-scrollbar {
		display: none
	}

	::v-deep .chart-card {
		height: calc(100vh - 145px);
		overflow: auto;
	}

	::v-deep .subject {
		margin-left: 3px;
		font-size: var(--el-form-label-font-size);
		font-weight: bold;
		margin-bottom: 5px;
		color: var(--el-text-color-regular);

		.xingxing {
			color: var(--el-color-danger);
		}
	}

	// .w-e-text-container [data-slate-editor] p{
	//   margin-top: 22px;
	//   margin-bottom: 22px;
	// }
	::v-deep .el-button {
		width: 100px;
		height: 40px;
		font-size: 16px;
	}

	::v-deep .el-form-item__content {
		align-items: flex-end;
	}

	::v-deep .referrals {
		color: #cccccc;
		margin-left: 5px;
		font-weight: 500;
	}

	::v-deep .styB .el-upload--picture-card {
		width: 110px;
		height: 110px;
		line-height: 110px;
	}

	::v-deep .styC .el-upload--picture-card {
		display: none;
	}

	::v-deep .el-form-item {
		align-items: center;
	}

	::v-deep .aaaa {
		width: 50%;
	}

	::v-deep .el-card__body {
		padding: 0;
	}

	::v-deep .el-main {
		overflow: auto !important;
	}

	::v-deep .el-card.is-always-shadow {
		width: 95%;
		margin: auto;
		margin-top: 10px;
		padding-left: 20px;
		padding-right: 20px;
		padding-bottom: 20px;
	}

	::v-deep .tabulation {
		margin-top: 10px;
		display: flex;
		justify-content: center;
		align-items: center;
		margin-bottom: 10px;
		overflow: auto !important;
	}

	.hint {
		text-align: justify;
		display: flex;
		font-size: 14px;
		margin-left: 75px;
		/* margin-top: -10px; */
		margin-bottom: 20px;
	}

	.remind {
		/* width: 65px; */
		color: red;
		font-size: var(--el-form-label-font-size);
		font-weight: bold;
	}

	.become {
		color: red;
	}
</style>
<style scoped>
	::v-deep .upload-demo {
		display: flex;
		width: 100%;
	}

	::v-deep .upload-demo .el-upload-list {
		display: flex;
		width: 50%;
	}

	::v-deep .el-input {
		height: 44px !important;
	}

	::v-deep .el-form-item__label {
		width: 125px !important;
		font-weight: bold;
	}

	::v-deep .submit {
		margin-left: 125px;
	}
</style>